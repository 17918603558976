<template>
  <div>
    <FilterWrap
      v-show="showFilter"
      v-model="filterValue"
      @confirm="resetList"
    />
    <div v-show="!showFilter" class="agricultural-technology">
      <HeaderTabs :tabs="tabs" :activeName="activeName" @click="handleTabClick">
        <div
          v-if="activeName === '自有农技会'"
          class="navigate-wrap"
          @click="navigateAddPage"
        >
          <span>添加</span>
          <img src="../../assets/images/common/add.png" alt="" />
        </div>
      </HeaderTabs>
      <div class="search-wrap">
        <SearchWrap v-model="searchTotal" @search="resetList"></SearchWrap>
        <div class="sort-wrap">
          <img
            src="../../assets/images/common/sort.png"
            alt=""
            @click="showSortDialog"
          />
          <img
            src="../../assets/images/common/filter.png"
            alt=""
            @click="showFilterDialog"
          />
        </div>
      </div>
      <div class="list-wrap">
        <CustomCard
          class="mb-12"
          v-for="(item, index) in list"
          :key="index"
          :title="''"
          :content="formatCardContent(item)"
        >
          <div class="action-wrap">
            <CustomButton size="mini" @click="navigateDetailPage(item.id)"
              >查看</CustomButton
            >
            <CustomButton
              size="mini-plain"
              @click="
                navigateAddFertPage(
                  item.id,
                  item.techconferenceType,
                  item.createBy,
                  item.holdTime
                )
              "
              >添加订肥信息</CustomButton
            >
          </div>
        </CustomCard>
      </div>
      <infinite-loading ref="infinite" @infinite="infiniteHandler">
        <span slot="no-more">
          暂无更多
        </span>
      </infinite-loading>
    </div>
    <SortDialog
      v-model="sortValue"
      ref="sort"
      :sortData="sortData"
      @confirm="resetList"
    />
  </div>
</template>
<script>
import HeaderTabs from "@/components/header/headerTabs";
import SearchWrap from "@/components/common/search";
import CustomCard from "@/components/common/card";
import CustomButton from "@/components/common/button/CustomButton.vue";
import InfiniteLoading from "vue-infinite-loading";
import { agriculturalTechnologyApi } from "@/utils/api.js";
import FilterWrap from "./Filter";
import SortDialog from "@/components/dialog/SortDialog.vue";
import { getDealerId } from "@/utils/user";
import dayjs from "dayjs";
export default {
  components: {
    HeaderTabs,
    SearchWrap,
    CustomCard,
    CustomButton,
    InfiniteLoading,
    SortDialog,
    FilterWrap,
  },
  data() {
    return {
      activeName: "史丹利农技会",
      tabs: [{ title: "史丹利农技会" }, { title: "自有农技会" }],
      searchTotal: "",
      list: [],
      pageNo: 1,
      pageSize: 5,
      total: 0,
      showFilter: false,
      filterValue: {},
      sortValue: "",
      sortData: [
        {
          label: "参会人数",
          names: ["(personNum + 0)", "(personNum + 0) DESC"],
        },
        { label: "订肥量", names: ["(orderNum + 0)", "(orderNum + 0) DESC"] },
      ],
    };
  },
  activated() {
    try {
      this.filterValue =
        JSON.parse(window.localStorage.getItem("shidanli-filter")) || {};
      // window.localStorage.setItem("shidanli-filter", "");
    } catch (err) {
      this.filterValue = {};
    }
    this.resetList();
  },
  methods: {
    // 切换tab
    handleTabClick(tab) {
      this.activeName = tab;
      this.searchTotal = "";
      this.filterValue = {};
      this.sortValue = "";
      this.resetList();
    },
    // 搜索
    resetList() {
      this.showFilter = false;
      this.$refs.infinite._events["$InfiniteLoading:reset"][0]();
      this.list = [];
      this.pageNo = 1;
      this.total = 0;
    },
    async infiniteHandler($state) {
      try {
        await this.search();
      } catch (err) {
        //
      }
      $state.loaded();
      this.pageNo += 1;
      if (this.total - this.list.length === 0) {
        $state.complete();
      }
    },
    async search(clear) {
      if (clear) {
        this.resetList();
      }
      let response = {};
      if (this.activeName === "史丹利农技会") {
        response = await agriculturalTechnologyApi.list({
          "page.pageNo": this.pageNo,
          "page.pageSize": this.pageSize,
          searchTotal: this.searchTotal,
          "page.orderBy": this.sortValue,
          ...this.filterValue,
        });
      } else {
        response = await agriculturalTechnologyApi.distList({
          "page.pageNo": this.pageNo,
          "page.pageSize": this.pageSize,
          searchTotal: this.searchTotal,
          "page.orderBy": this.sortValue,
          ...this.filterValue,
        });
      }
      if (response.page.total && this.pageNo === 1) {
        this.$toast(`共${response.page.total}条数据`)
      }
      this.list = this.list.concat(response.data || []);
      this.total = (response.page && response.page.total) || 0;
    },
    formatCardContent(item) {
      return [
        { label: "农技会类型", value: item.techconferenceType },
        // {
        //   label: "所属种植大户",
        //   value: item.kjBigfarmersName,
        //   hide: item.techconferenceTypeValue !== "1",
        // },
        {
          label: "所属终端",
          value: item.kjTerminalNodeName,
          hide: ["1", "3"].includes(item.techconferenceTypeValue),
        },
        {
          label: "所属示范田",
          value: item.kjModelFieldName,
          hide: item.techconferenceTypeValue !== "3",
        },
        { label: "参会人数", value: `${item.personNum || 0}人` },
        { label: "订肥量", value: `${item.orderNum || 0}吨` },
        { label: "会议时间", value: item.holdTime },
      ];
    },
    navigateAddPage() {
      this.$router.push({ name: "AgriculturalTechnologyAdd" });
    },
    navigateDetailPage(id) {
      if (this.activeName === "史丹利农技会") {
        this.$router.push({
          name: "AgriculturalTechnologyShidanliDetail",
          params: { id },
        });
      } else {
        this.$router.push({
          name: "AgriculturalTechnologyPersonDetail",
          params: { id },
        });
      }
    },
    navigateAddFertPage(id, type, createBy, holdTime) {
      if (createBy !== getDealerId()) {
        this.$toast("只能由创建人添加订肥数据");
        return;
      }
      const dayGap = dayjs(new Date()).diff(dayjs(holdTime));
      if (dayGap / (1000 * 60 * 60) > 48) {
        this.$toast("超过48小时，不允许添加订肥信息");
        return;
      }
      this.$router.push({
        name: "AgriculturalTechnologyAddFertilizer",
        params: { id },
        query: { type, activeName: this.activeName },
      });
    },
    // 顶部操作
    showSortDialog() {
      this.$refs.sort.showDialog();
    },
    showFilterDialog() {
      this.$router.push({ path: "/agriculturalTechnology/filter" });
    },
    navigateMapPage() {
      this.$router.push({
        path: `/map?type=agriculturalTechnology&tab=${
          this.activeName === "史丹利农技会" ? "shidanli" : "ziyou"
        }`,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.agricultural-technology {
  width: 100%;
  min-height: 100vh;
  background-color: #fafbfc;
  .navigate-wrap {
    position: absolute;
    top: 12px;
    right: 24px;
    font-size: 14px;
    color: #ffffff;
    display: flex;
    align-items: center;
    span {
      flex-shrink: 0;
    }
    img {
      height: 14px;
      width: auto;
      margin-left: 4px;
    }
  }
  .search-wrap {
    padding: 10px 24px 10px 12px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .sort-wrap {
      flex-shrink: 0;
      img {
        height: 14px;
        width: auto;
        margin-left: 20px;
      }
    }
  }
  .list-wrap {
    padding: 12px;
    .action-wrap {
      text-align: right;
      button {
        margin-left: 12px;
      }
    }
  }
}
</style>
